import { Title } from '@solidjs/meta';
import { Button, Label, Switch, useToast } from '@troon/ui';
import { createSignal } from 'solid-js';
import { StyleCard } from '../_style-card';

export default function ToastPage() {
	const addToast = useToast();
	const [autoDismissToasts, setAutoDismissToasts] = createSignal(false);
	return (
		<>
			<Title>Toast | Components | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Toast</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Toast">
					<Switch
						name="toastTimeout"
						onChange={(e) => {
							setAutoDismissToasts(e.currentTarget.checked);
						}}
					>
						<Label>Auto-dismiss</Label>
					</Switch>

					<Button
						onClick={() => {
							addToast('This is a toast', { timeout: autoDismissToasts() ? 5000 : undefined });
						}}
					>
						Add info toast
					</Button>
					<Button
						onClick={() => {
							addToast('This is a positive message', {
								variant: 'positive',
								timeout: autoDismissToasts() ? 5000 : undefined,
							});
						}}
					>
						Add positive toast
					</Button>
					<Button
						onClick={() => {
							addToast('This is a negative message', {
								variant: 'negative',
								timeout: autoDismissToasts() ? 5000 : undefined,
							});
						}}
					>
						Add negative toast
					</Button>
				</StyleCard>
			</div>
		</>
	);
}
